import { createStore } from "vuex";
import { auth } from "./auth.module";
import {blackbook} from "./blackbook.module"

const store = createStore({
  modules: {
    auth,
    blackbook,
  },
});

export default store;

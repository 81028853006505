import axios from "axios";
import authHeader from "./auth-header";
import globalVars from "../functions/globalVars.js";
import store from "../store/index";
const endPoint = globalVars.getApiEndPoint();
const API_URL = endPoint + "/api/blackbook/";

class BlackbookService {
  //horses
  getHorses(search, type, userId) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "horse-search/" + search + "/" + type + "/" + userId, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  getUserHorses(userId, startIndex, pageSize) {
    return axios
      .get(
        API_URL +
        "blackbook-horses/" +
        userId +
        "/" +
        startIndex +
        "/" +
        pageSize,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log("error: " + err);
        store.dispatch("auth/logout");
        //this.$router.push("/login");
        window.location.href = "/blackbook/login";
      });
  }

  getUserAcceptedHorses(userId) {
    return axios
      .get(API_URL + "userhorseaccepted/" + userId, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserHorsesLatestResults(userId) {
    return axios
      .get(API_URL + "userhorseslatestresults/" + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  addUserHorse(userHorse) {
    var data = new FormData();
    data.append("UserId", userHorse.UserId);
    data.append("Horsename", userHorse.Horsename);
    data.append("HorseCountry", userHorse.HorseCountry);
    data.append("HorseMasterId", userHorse.HorseMasterId);
    data.append("AapHorseId", userHorse.AapHorseId);
    data.append("Comment", userHorse.Comment);

    return axios
      .post(API_URL + "userhorseadd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserHorse(userHorse) {
    var data = new FormData();
    data.append("UserId", userHorse.UserId);
    data.append("Horsename", userHorse.Horsename);
    data.append("HorseCountry", userHorse.HorseCountry);
    data.append("HorseMasterId", userHorse.HorseMasterId);
    data.append("AapHorseId", userHorse.AapHorseId);
    data.append("Comment", userHorse.Comment);
    data.append("Id", userHorse.Id);

    return axios
      .post(API_URL + "userhorseupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserHorse(userHorse) {
    var data = new FormData();
    data.append("UserId", userHorse.UserId);
    data.append("Horsename", userHorse.Horsename);
    data.append("HorseCountry", userHorse.HorseCountry);
    data.append("HorseMasterId", userHorse.HorseMasterId);
    data.append("AapHorseId", userHorse.AapHorseId);
    data.append("Comment", userHorse.Comment);
    data.append("Id", userHorse.Id);

    return axios
      .post(API_URL + "userhorsedelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  userHorseExists(userId, aapId) {
    return axios
      .get(API_URL + "userhorseexists/" + userId + "/" + aapId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  horseByAapId(aapId) {
    return axios
      .get(API_URL + "horsebyaapid/" + aapId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  //Unraced Horses

  getUserUnracedHorses(userId) {
    return axios
      .get(API_URL + "blackbook-unraced-horses/" + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  addUserUnracedHorse(userHorse) {
    var data = new FormData();
    data.append("UserId", userHorse.UserId);
    data.append("Horsename", userHorse.Horsename);
    data.append("HorseCountry", userHorse.HorseCountry);
    data.append("HorseYOB", userHorse.HorseYOB);
    data.append("Sire", userHorse.Sire);
    data.append("Dam", userHorse.Dam);
    data.append("Comment", userHorse.Comment);

    return axios
      .post(API_URL + "userunracedhorseadd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserUnracedHorse(userHorse) {
    var data = new FormData();
    data.append("UserId", userHorse.UserId);
    data.append("Horsename", userHorse.Horsename);
    data.append("HorseCountry", userHorse.HorseCountry);
    data.append("HorseYOB", userHorse.HorseYOB);
    data.append("Sire", userHorse.Sire);
    data.append("Dam", userHorse.Dam);
    data.append("Comment", userHorse.Comment);
    data.append("Id", userHorse.Id);

    return axios
      .post(API_URL + "userunracedhorseupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserUnracedHorse(userHorse) {
    var data = new FormData();
    data.append("UserId", userHorse.UserId);
    data.append("Horsename", userHorse.Horsename);
    data.append("Comment", userHorse.Comment);
    data.append("Id", userHorse.Id);

    return axios
      .post(API_URL + "userunracedhorsedelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //trainers
  trainerSearch(search, userId) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "trainer-search/" + search + "/" + userId, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  addUserTrainer(userTrainer) {
    var data = new FormData();
    data.append("UserId", userTrainer.UserId);
    data.append("Trainer", userTrainer.Trainer);
    data.append("BreednetId", userTrainer.BreednetId);
    data.append("AapTrainerId", userTrainer.AapTrainerId);
    data.append("Comment", userTrainer.Comment);

    return axios
      .post(API_URL + "usertraineradd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserTrainer(userTrainer) {
    var data = new FormData();
    data.append("UserId", userTrainer.UserId);
    data.append("Trainer", userTrainer.Trainer);
    data.append("BreednetId", userTrainer.BreednetId);
    data.append("AapTrainerId", userTrainer.AapTrainerId);
    data.append("Comment", userTrainer.Comment);
    data.append("Id", userTrainer.Id);

    return axios
      .post(API_URL + "usertrainerupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserTrainer(userTrainer) {
    var data = new FormData();
    data.append("UserId", userTrainer.UserId);
    data.append("Trainer", userTrainer.Trainer);
    data.append("BreednetId", userTrainer.BreednetId);
    data.append("AapTrainerId", userTrainer.AapTrainerId);
    data.append("Comment", userTrainer.Comment);
    data.append("Id", userTrainer.Id);

    return axios
      .post(API_URL + "usertrainerdelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserTrainers(userId) {
    return axios
      .get(API_URL + "blackbook-user-trainers/" + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getUserTrainerUpcomingRunners(aapId) {
    return axios
      .get(API_URL + "user-trainer-get-runners/" + aapId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  //jockeys
  jockeySearch(search, userId) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "jockey-search/" + search + "/" + userId, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  addUserJockey(userJockey) {
    var data = new FormData();
    data.append("UserId", userJockey.UserId);
    data.append("Jockey", userJockey.Jockey);
    data.append("BreednetId", userJockey.BreednetId);
    data.append("AapJockeyId", userJockey.AapJockeyId);
    data.append("Comment", userJockey.Comment);

    return axios
      .post(API_URL + "userjockeyadd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserJockey(userJockey) {
    var data = new FormData();
    data.append("UserId", userJockey.UserId);
    data.append("Jockey", userJockey.Jockey);
    data.append("BreednetId", userJockey.BreednetId);
    data.append("AapJockeyId", userJockey.AapJockeyId);
    data.append("Comment", userJockey.Comment);
    data.append("Id", userJockey.Id);

    return axios
      .post(API_URL + "userjockeyupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserJockey(userJockey) {
    var data = new FormData();
    data.append("UserId", userJockey.UserId);
    data.append("Jockey", userJockey.Jockey);
    data.append("BreednetId", userJockey.BreednetId);
    data.append("AapJockeyId", userJockey.AapJockeyId);
    data.append("Comment", userJockey.Comment);
    data.append("Id", userJockey.Id);

    return axios
      .post(API_URL + "userjockeydelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserJockeys(userId) {
    return axios
      .get(API_URL + "blackbook-user-jockeys/" + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getUserJockeyUpcomingRunners(aapId) {
    return axios
      .get(API_URL + "user-jockey-get-runners/" + aapId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  //Sires
  addUserSire(userSire) {
    var data = new FormData();
    data.append("UserId", userSire.UserId);
    data.append("Sire", userSire.Sire);
    data.append("SireCountry", userSire.SireCountry);
    data.append("BreednetId", userSire.BreednetId);
    data.append("Comment", userSire.Comment);

    return axios
      .post(API_URL + "usersireadd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserSire(userSire) {
    var data = new FormData();
    data.append("UserId", userSire.UserId);
    data.append("Sire", userSire.Sire);
    data.append("SireCountry", userSire.SireCountry);
    data.append("BreednetId", userSire.BreednetId);
    data.append("Comment", userSire.Comment);
    data.append("Id", userSire.Id);

    return axios
      .post(API_URL + "usersireupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserSire(userSire) {
    var data = new FormData();
    data.append("UserId", userSire.UserId);
    data.append("Sire", userSire.Sire);
    data.append("SireCountry", userSire.SireCountry);
    data.append("BreednetId", userSire.BreednetId);
    data.append("Comment", userSire.Comment);
    data.append("Id", userSire.Id);

    return axios
      .post(API_URL + "usersiredelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserSires(userId) {
    return axios
      .get(API_URL + "blackbook-user-sires/" + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getUserSireUpcomingRunners(sire, sireCountry) {
    return axios
      .get(API_URL + "user-sire-get-runners/" + sire + "/" + sireCountry, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  //Dams

  damSearch(search, userId) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "dam-search/" + search + "/" + userId, { headers: header })
      .then((response) => {
        return response.data;
      });
  }

  addUserDam(addUserDam) {
    var data = new FormData();
    data.append("UserId", addUserDam.UserId);
    data.append("Dam", addUserDam.Dam);
    data.append("DamCountry", addUserDam.DamCountry);
    data.append("BreednetId", addUserDam.BreednetId);
    data.append("Comment", addUserDam.Comment);

    return axios
      .post(API_URL + "userdamadd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserDam(userDam) {
    var data = new FormData();
    data.append("UserId", userDam.UserId);
    data.append("Dam", userDam.Dam);
    data.append("DamCountry", userDam.DamCountry);
    data.append("BreednetId", userDam.BreednetId);
    data.append("Comment", userDam.Comment);
    data.append("Id", userDam.Id);

    return axios
      .post(API_URL + "userdamupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserDam(userDam) {
    var data = new FormData();
    data.append("UserId", userDam.UserId);
    data.append("Dam", userDam.Dam);
    data.append("DamCountry", userDam.DamCountry);
    data.append("BreednetId", userDam.BreednetId);
    data.append("Comment", userDam.Comment);
    data.append("Id", userDam.Id);

    return axios
      .post(API_URL + "userdamdelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserDams(userId) {
    return axios
      .get(API_URL + "blackbook-user-dams/" + userId, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserDamUpcomingRunners(dam, damCountry) {
    return axios
      .get(API_URL + "user-dam-get-runners/" + dam + "/" + damCountry, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  //Nicks

  sireOfDamSearch(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "sire-of-dam-search/" + search, { headers: header })
      .then((response) => {
        return response.data;
      });
  }

  addUserNick(addUserNick) {
    var data = new FormData();
    data.append("UserId", addUserNick.UserId);
    data.append("Sire", addUserNick.Sire);
    data.append("SireCountry", addUserNick.SireCountry);
    data.append("SireBreednetId", addUserNick.SireBreednetId);
    data.append("SireOfDam", addUserNick.SireOfDam);
    data.append("SireOfDamCountry", addUserNick.SireOfDamCountry);
    data.append("SireOfDamBreednetId", addUserNick.SireOfDamBreednetId);
    data.append("Comment", addUserNick.Comment);

    return axios
      .post(API_URL + "usernickadd", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateUserNick(userNick) {
    var data = new FormData();
    data.append("UserId", userNick.UserId);
    data.append("Sire", userNick.Sire);
    data.append("SireCountry", userNick.SireCountry);
    data.append("SireBreednetId", userNick.SireBreednetId);
    data.append("SireOfDam", userNick.SireOfDam);
    data.append("SireOfDamCountry", userNick.SireOfDamCountry);
    data.append("SireOfDamBreednetId", userNick.SireOfDamBreednetId);
    data.append("Comment", userNick.Comment);
    data.append("Id", userNick.Id);

    return axios
      .post(API_URL + "usernickupdate", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteUserNick(userNick) {
    var data = new FormData();
    data.append("UserId", userNick.UserId);
    data.append("Sire", userNick.Sire);
    data.append("SireCountry", userNick.SireCountry);
    data.append("SireBreednetId", userNick.SireBreednetId);
    data.append("SireOfDam", userNick.SireOfDam);
    data.append("SireOfDamCountry", userNick.SireOfDamCountry);
    data.append("SireOfDamBreednetId", userNick.SireOfDamBreednetId);
    data.append("Comment", userNick.Comment);
    data.append("Id", userNick.Id);

    return axios
      .post(API_URL + "usernickdelete", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUserNicks(userId) {
    return axios
      .get(API_URL + "blackbook-user-nicks/" + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getUserNickUpcomingRunners(sire, sireCountry, sireOfDam, sireOfDamCountry) {
    return axios
      .get(
        API_URL +
        "user-nick-get-runners/" +
        sire +
        "/" +
        sireCountry +
        "/" +
        sireOfDam +
        "/" +
        sireOfDamCountry,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getUserAcceptedCount(userId) {
    return axios
      .get(API_URL + "useracceptedcount/" + userId, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //sales
  getPresaleSaleNames() {
    return axios
      .get(API_URL + "getsalemaressales/", { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPreSaleSires(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "getsalemaresires/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  getPreSaleDams(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "getsalemaredams/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  getPreSaleDamSires(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "getsalemaredamsires/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  presaleSearch(searchParams) {
    var header = {
      "Content-Type": "application/json; charset=utf8",
    };

    return axios
      .post(API_URL + "presalesearch", searchParams, { headers: header })
      .then((response) => {
        return response.data;
      });
  }

  // main end points
  getlatestSales() {
    return axios
      .get(API_URL + "getsales/", { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getSaleSires(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "getsalesires/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  getSaleDams(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "getsaledams/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  getSaleDamSires(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "getsaledamsires/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }

  getVendorList() {
    return axios
      .get(API_URL + "getvendors/", { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }


  saleSearch(searchParams) {
    var header = {
      "Content-Type": "application/json; charset=utf8",
    };

    return axios
      .post(API_URL + "salesearch", searchParams, { headers: header })
      .then((response) => {
        return response.data;
      });
  }

  racedHorseCheck(horseYob, sire, dam) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "checkhorseraced/" + horseYob + "/" + sire + "/" + dam, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }


  //Families
  getHorsemasterFemaleSearch(search) {
    var header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(API_URL + "families-female-search/" + search, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new BlackbookService();

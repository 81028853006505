import blackbookService from "../services/blackbook.service";

export const blackbook = {
    namespaced: true,
    actions: {
        horsesearch(searchTerm, horseType) {
            return blackbookService.getHorses(searchTerm, horseType)
        },
        
        getUserHorses(userId) {
            return blackbookService.getUserHorses(userId)
        },
  }
}
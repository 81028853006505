import { createStore } from "vuex";
import { auth } from "../../../../breednet-blackbook-frontend/src/store/auth.module";
import {blackbook} from "../../../../breednet-blackbook-frontend/src/store/blackbook.module"

const store = createStore({
  modules: {
    auth,
    blackbook,
  },
});

export default store;

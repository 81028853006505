<template>
  <div class="bb-user-acceptors" v-if="currentUser">
    <a href="/blackbook" class="bb-link">
      <div
        class="row"
        v-if="
          userAcceptedHorses.acceptedHorses > 0 ||
          userAcceptedHorses.acceptedTrainers > 0 ||
          userAcceptedHorses.acceptedJockeys > 0 ||
          userAcceptedHorses.acceptedSires > 0 ||
          userAcceptedHorses.acceptedDams > 0 ||
          userAcceptedHorses.AcceptedNicks > 0
        "
      >
        <div class="col-12">
          <span class="bb-header">Blackbook Acceptors</span> -
          <span v-if="userAcceptedHorses.acceptedHorses > 0">
            <span class="bb-title">Horses: </span>
            <span class="bb-badge">{{
              userAcceptedHorses.acceptedHorses
            }}</span>
          </span>
          <span v-if="userAcceptedHorses.acceptedTrainers > 0">
            <span class="bb-title">Trainers: </span>
            <span class="bb-badge">{{
              userAcceptedHorses.acceptedTrainers
            }}</span>
          </span>
          <span v-if="userAcceptedHorses.acceptedJockeys > 0">
            <span class="bb-title">Jockeys: </span>
            <span class="bb-badge">{{
              userAcceptedHorses.acceptedJockeys
            }}</span>
          </span>
          <span v-if="userAcceptedHorses.acceptedSires > 0">
            <span class="bb-title">Sires: </span>
            <span class="bb-badge">{{ userAcceptedHorses.acceptedSires }}</span>
          </span>
          <span v-if="userAcceptedHorses.acceptedDams > 0">
            <span class="bb-title">Dams: </span>
            <span class="bb-badge">{{ userAcceptedHorses.acceptedDams }}</span>
          </span>
          <span v-if="userAcceptedHorses.acceptedNicks > 0">
            <span class="bb-title">Nicks: </span>
            <span class="bb-badge">{{ userAcceptedHorses.acceptedNicks }}</span>
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import blackbookService from "../../../../breednet-blackbook-frontend/src/services/blackbook.service";
import store from "../store";
export default {
  name: "UserAcceptors",
  data() {
    return {
      userAcceptedHorses: {},
      userid: "",
    };
  },
  computed: {
    currentUser() {
      return store.state.auth.user;
    },
  },
  mounted() {
    if (this.currentUser) {
      this.userid = this.currentUser.id;
      blackbookService
        .getUserAcceptedCount(this.userid)
        .then((response) => {
          this.userAcceptedHorses = response;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style>
.bb-header {
  font-weight: bold;
  font-size: 1.1em;
}
.bb-title {
  font-weight: bold;
  padding-left: 0.5em;
}

.bb-badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --bs-bg-opacity: 1;
  background-color: #28a745 !important;
}
.bb-link {
  text-decoration: none;
}
.bb-user-acceptors {
  margin-bottom: 1em;
  background: #ececec;
  padding: 0.75em;
}
</style>
